import { lazy } from "react";
import Loadable from "../ui-component/Loadable";
const AuthGuard = Loadable(
  lazy(() => import("../views/pages/authentication/AuthGuard"))
);
const MainLayout = Loadable(lazy(() => import("../layout/MainLayout")));
const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
);
const MainOrders = Loadable(lazy(() => import("../views/order/MainOrders")));
const OrderDetails = Loadable(
  lazy(() => import("../views/order/OrderDetails"))
);




//order
const ReviewOrder = Loadable(
  lazy(() => import("../views/order/review/ReviewOrder"))
);


const ChangePassword = Loadable(
  lazy(() => import("../views/pages/authentication/ChangePassword"))
);


//performance Dialy
const DialyPerformance = Loadable(
  lazy(() => import("../views/report/performance/DialyPerformance"))
);
const WeeklyPerformance = Loadable(
  lazy(() => import("../views/report/performance/WeeklyPerformance"))
);
const MonthlyPerformance = Loadable(
  lazy(() => import("../views/report/performance/MonthlyPerformance"))
);

//Summary
const DialySummary = Loadable(
  lazy(() => import("../views/report/summary/DialySummary"))
);
const WeeklySummary = Loadable(
  lazy(() => import("../views/report/summary/WeeklySummary"))
);
const MonthlySummary = Loadable(
  lazy(() => import("../views/report/summary/MonthlySummary"))
);

//notification
const Notification = Loadable(
  lazy(() => import("../views/userNotification/userNotification"))
);
const UserUpdate = Loadable(
  lazy(() => import("../views/pages/authentication/userupdate"))
);
const OnlineOrder = Loadable(
  lazy(() => import("../views/report/onlineOrder/OnlineOrder"))
);

const MonthlyInvoice = Loadable(
  lazy(() => import("../views/report/MonthlyInvoice"))
);
//menu
const ViewTaxInvoice =  Loadable(
  lazy(() => import("../views/taxInvoice/view"))
);
const LedgerList = Loadable(
  lazy(() => import("../views/ledger/LedgerList"))
)
const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
   
   
  
    {
      path: "/branch",
      children: [
        {
          path: "order",
          element: <MainOrders />,
        },
      ],
    },
    {
      path: "/order",
      children: [
        {
          path: "view",
          element: <MainOrders />,
        },
        {
          path: "orderDetails/:id",
          element: <OrderDetails />,
        },
      ],
    },
    {
      path: "review",
      element: <ReviewOrder />,
    },
   
    {
      path: "/report",
      children: [
        {
          path: "performance_report",
          children: [
            {
              path: "dialy",
              element: <DialyPerformance />,
            },
            {
              path: "weekly",
              element: <WeeklyPerformance />,
            },
            {
              path: "monthly",
              element: <MonthlyPerformance />,
            },
          ],
        },
        {
          path: "summary_report",
          children: [
            {
              path: "dialy",
              element: <DialySummary />,
            },
            {
              path: "weekly",
              element: <WeeklySummary />,
            },
            {
              path: "monthly",
              element: <MonthlySummary />,
            },
          ],
        },
        {
          path: "invoice",
          element: <MonthlyInvoice />,
        },
        {
          path: "onlineOrder",
          element: <OnlineOrder />,
        },
      ],
    },
    {
      path: "/userUpdate",
      element: <UserUpdate />,
    },
    {
      path: "/profile",
      element: <ChangePassword />,
    },
    {
      path: "/notification/view",
      element: <Notification />,
    },
    {
      path: "/taxinvoice",
      children: [
        {
          path: "view",
          element: <ViewTaxInvoice />,
        },
      ],
    },
    {
      path: "/ledger",
      element: <LedgerList />,
    },
  
  ],
};

export default MainRoutes;
